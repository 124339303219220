import { toast } from 'react-toastify';

const defaultErrorMessage = 'Une erreur est survenue';

const handleFormError = (error: unknown) => {
  const err = error as Error & {
    validationErrorMessage?: string;
    messageText?: string;
    details?: { message: string }[];
  };

  const validationErrorMessage = err.details?.[0]?.message;

  const message =
    validationErrorMessage ||
    err.messageText ||
    err.message ||
    defaultErrorMessage;

  toast.error(message);

  return message;
};

export default handleFormError;
